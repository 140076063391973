*{
    font-family: 'Lato', sans-serif;
}

.container-fluid {
    background-color: #ffffff;
}

.heading{
    font-family: Lato;
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #e74c3c;
    padding: 30px;
}


.form-control{
    padding: 25px 0px;
    font-size: 16px;
    border: none;
    /* background-color: #faf5f2; */
    background-color:#fdf8f8;
    padding: 0px 10px;
    margin: 0px;
}


.form-control:focus {

    border-color: #ff80ff;
    box-shadow: none;
}

.form-group{
    /* background-color: #a8a8a8; */
    background-color:#fdf8f8;
}
.form-group i{
    float: left;
}

.email{
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #e74c3c;
    padding: 0px 10px;
  }

.remember-text{
    font-family: 'Lato', sans-serif;
    /* font-family: Lato; */
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #e74c3c;
    padding: 10px 0px;
}

.remember-text a{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #e74c3c;
    padding: 10px 0px;
}

.btn{
    background-color: #e74c3c;
    color: #ffffff;
    font-size: 16px;
    height: 50px;
    font-weight: bold;
}
button.btn.btn-block{
    width: 21rem;
}

.sign-up{
    text-align: center;
    font-size: 20px;
    padding: 10px 0px;
    color: #6d6d6d;
}

.sign-up a{
    color: #e74c3c;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
}



@media (max-width: 800px) {

}