body{
    margin: 0px;
    padding: 0px;
}

*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
/* ********* logo and nav Header ******** */
header{
    margin: 0px;padding: 0px;
    height: 90px;
    text-align: right;
}

.logo{
    margin-left: 10px;;
    padding: 0px;
    text-align: center;

}

.col-md-5.header-right{
    display: none;
}

.myimg{
    height: 90px;
    width:90px;
}

header .header-right{
    margin: 0px;padding: 0px;
    box-sizing: border-box;
    text-align: right;
    margin: auto;
}
.center{
    text-align: center;
}
.check-fs{
    font-size: 1.5rem;
}
.muted-cl, .muted-cl::after, .muted-cl::before{
    color: #adadad;
    border-color: #adadad!important;
}
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 5px solid rgb(151, 147, 147);
}
.separator-green::before, .separator-green::after {
    content: '';
    flex: 1;
    border-bottom: 5px solid rgb(5, 221, 52);
}
.separator-red::before, .separator-red::after {
    content: '';
    flex: 1;
    border-bottom: 5px solid rgb(221, 5, 5);
}

.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}

button#dropdownMenuButton{
    background-color: white;
    outline: none;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: none;
}
.dropdown {
    position: relative;
    display: inline-block;
  }
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 0;
    z-index: 1;
  }
  
  .dropdown-content{
    color: black;
    padding: 5px 0px;
    text-decoration: none;
    display: block;
    border-top:1px solid #ddd;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}
  
  /* ------------------ */
  .dropdown-menu a{
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #e74c3c;
  
  }
  .dropdown-menu.dropdown-menu-right.show{
      top:auto;
  } 


header .drop-down{
    float: left;
    line-height: 40px;
    display: flex;
    border-radius: 30px;
    margin-top: 25px;
}

header strong{
    color:#e74c3c;
    display: inline;
    padding: 0px 10px;
}

header .drop-down img{
    height: 20px;
    width: 20px;
    padding: 10px 0px 0px 10px;
}

header .header-right .fa-bell{
    font-size: 35px;
    float: left;
    padding: 25px;
}

header .header-right .profile{
    width:40px;
    height:40px;
    border-radius: 50%;
    border:2px solid red;
    float: left;
    margin-top: 25px;
}

header .header-right .fa-ellipsis-v{
    /* float:left; */
    font-size: 25px;
    padding: 30px;
    color: #6d6d6d;
    
}

/* *******Side Menu********* */

.main-body .side-menu{
    text-align: center;
    box-shadow: 4px 0 5px -5px black;
    
}
.side-menu{
    height:100vh;
}



.right-side{
    background: #f6f6f6;
    box-sizing: border-box;
}
.row{
    margin: 0 !important;
    padding: 0 !important;
}

/* Search Box */
.serach{
    background-color: #ffffff;
    border-radius:30px;
    margin-top: 10px;
}

.input-group-text{
     background-color: #6d6d6d;
    border-radius: 50%;
    border: 0px solid black;
}

.form-control{
    border: none;
    border-radius: 40px;
    border: 0;
}

.search-nav{
    background-color: #e74c3c; 
    height: 58px;
}

.search-nav p{
    color: #ffffff; 
    font-weight: bold;
    line-height: 58px;
}


/* ******add branch********* */

.add-branch{
    background-color: #ffffff;
    border-radius: 30px;
    float: right;
    width: 150px;
    height: 40px;
    line-height: 40px;
}

.add-branch i{
    color: #e74c3c;
    margin-left: 10px;
}

.add-branch strong{
   color: #e74c3c; 
   margin-left: 5px;
}

.drop-down .btn{
    background: #ffffff;
    border-radius: 30px;
    border: 2px solid #e74c3c;
}

.menu{
    background: #ffffff;
    text-align: center;
    margin: 4px auto;
    padding: 6px;
    font-weight: bold;
    border-radius: 30px;
    width: 180px;
    color: #e74c3c;
    height: 40px;
    font-family: Lato;
}

.menu:hover{
    border: 2px solid #e74c3c;
}

.side-menu a{
    text-decoration: none;
}
.side-menu{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.side-menu{
    display:block;
}

/* ********Table to show info***********/

/* tr {
    border: 0;
    display: block;
    margin: 5px;
} */
th{
    margin: 50px;padding: 5px 15px;
}
.solid {
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
tr{
    margin-top: 10px;
}

td {
    padding: 5px;
}

.row-space { 
    border-collapse:separate; 
    border-spacing:0 15px; 
    text-align: center;
}

/* ******************* */

.navbar{
    background-color: #ffffff !important;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav .collapse-menu {
  text-decoration: none;
  font-size: 18px;
  color: #e74c3c;
  display: block;
  transition: 0.3s;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 30px;
  width: 180px;
  margin: auto;
  font-family: Lato;
  font-weight: bold;
}

.sidenav .collapse-menu:hover {
  border:2px solid #e74c3c;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: #e74c3c;
}
.dropdown-item{
    display: contents;
}
header .header-right {
   display:none
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

/* *********Screen Size 600px ********* */

@media only screen and (max-width:  576px) {
    header .header-right {
        display:inline-block;
     }
    .side-menu{
        display:none;
    }
    .smallbar{
        display:block !important;
    }
  
    .myimg{
        height: 40px;
        width:40px;
    }
    header .drop-down{
        float: left;
        line-height: 30px;
        display: flex;
        border-radius: 30px;
        margin-top: 15px;
    }

    header .drop-down img{
        height: 10px;
        width: 10px;
        padding: 50px 0px 0px 5px;
    }
    
    header .header-right .fa-bell{
        font-size: 20px;
        float: left;
        padding: 20px;
    }
    
    header .header-right .profile{
        width:30px;
        height:30px;
        border-radius: 50%;
        border:2px solid red;
        float: left;
        margin-top: 15px;
    }
    
    header .header-right .fa-ellipsis-v{
        float:left;
        font-size: 15px;
        padding: 20px;
        color: #6d6d6d;
        
    }

    .logo{
        margin:auto;
        padding: 0px;
        text-align: center;
    
    }

    .main-content th{
        font-size: 10px;
    }
    
    .main-content td{
        font-size: 8px;
    }
    

.menu{
    display: none;
}

.search-nav {
    height: 120px;
}
    
    .search-nav p{
        display:none;
    }

    .add-branch{
        background-color: #ffffff;
        border-radius: 30px;
        margin-top: 10px;
        margin-left: 10px;
        float: left;
        width: 120px;
        font-size: 10px;
        line-height: 40px;
    }
    
    .add-branch i{
        color: #e74c3c;
        margin-left: 10px;
    }
    
    .add-branch strong{
       color: #e74c3c; 
       margin-left: 5px;
    }
    .serach{
        background-color: #ffffff;
        border-radius:30px;
        margin-top: 5px;
    }
    button.btn.search-btn.w-100.p-0.m-0.btn-sm{
        color: #e74c3c;
    }
}

.selected_route div
{
    border:2px solid #e74c3c;
    border-radius: 92px 92px 92px 92px;
}

@media (min-width: 576px) and (max-width:720px){
    .menu{
        display: none;
    }
    header .drop-down{
        margin-left: 140px;
    }

    .main-content th{
        font-size: 14px;
    }
    
    .main-content td{
        font-size: 12px;
    }

}




@media only screen and (min-width: 720px) {
    .hide-button{
        display: none;
    }

}


.table-container {
    height: 40vw;
    width:100%;
}
.table-container  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}
.table-container  table tbody {
    /* body takes all the remaining available space */
    display: block;
    overflow: auto;
}
.table-container  table thead
 {
    display: table;
    width:100%;
    table-layout:fixed;
}

/* 

tbody {
    display:block;
    height:500px;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
.test{
    max-width: 150px;
} */

/* design for navbar */

.main-navbar{
    box-shadow: 0 3px 6px 0 rgba(231, 76, 60, 0.2);
    background-color: #ffffff;
  }
  
  .smallbar{
      display:none;
  }
  
  .dashboard-nav{
      background-color: #ffffff;
      padding-left:10px;
      /* border:1px salmon solid; */
  }
  
  .dashboard-nav .i-tag{
      font-size: 40px;
      color: #6d6d6d;
  }
  
  .dashboard-nav li{
      margin-left: 20px;
  }
  
  
  .dashboard-nav .profile{
      background: #e74c3c;
      border-radius: 50%;
      width: 50px;
      height: 50px;;
  }
  
  
  .nav-menu a{
    font-family: Lato;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #e74c3c;
  
  }
  
  /*********** drop down ********** */
  .dropbtn {
      background-color: #ffffff;
      color: white;
      padding: 20px;
      font-size: 16px;
      border: 1px;
      cursor: pointer;
    }
    
    .dropbtn :hover, .dropbtn:focus {
      background-color: #3e8e41;
    }
    button#dropdownMenuButton:focus{
      outline: none;
    }
    
    .dropdown {
      float: right;
      position: relative;
      display: inline-block;
    }
    #dropdownMenuButton{
      background-color:white;
    }
    
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      right: 0;
      z-index: 1;
    }
    
    .dropdown-content{
      color: black;
      padding: 5px 0px;
      text-decoration: none;
      display: block;
      border-top:1px solid #ddd;
      border-bottom: 1px solid #ddd;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
    }
    
    .dropdown a:hover {background-color: #ddd;}
    
    .show {display: block;}



    textarea{
        resize:none !important;
        padding-left: 24px !important;
    }
