.complain{
    box-shadow: 0 3px 6px 0 rgba(73, 95, 250, 0.2);
}

.heading{
    color: #393939;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
   
}

.content{
    color: #515151;
    font-family: Lato;
    font-size: 12px;
    
}

.message-content{
    border:1px solid rgba(73, 95, 250, 0.2);
}
.message-content p{
    padding: 5px;
    color: #6d6d6d;
    font-size: 14px;
    font-weight: normal;
}

.comment input{
    width: 100%;
    height: 50%
}

.comment-btn .btn-style{
    background: #928f8f;
    color: #ffffff;
}

.comment-btn .btn-style:hover{
    background-color: #e74c3c;
}


.btn-view{
    border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #6d6d6d;
  width: 100px;
  color: #ffffff;
}

.btn-view:hover{
    background-color: #e74c3c;
    color: #ffffff;
}
button.btn{
    background-color: #FAF7F6;
    color: black;
}
button.btn:hover{
   
    color:black;
}



.dropdown-btn{
  border-radius: 5px;
  border: solid 1px #707070;
  background-color: #ffffff;
}

.btn-save{
    background: #e74c3c;
    color: #ffffff;
    font-weight: bold;
    width:100px;
}
.heading{
    display:inline;
}
.dropdown{
    display:inline;
}

input.btn.btn-style{
    margin-right: 10px;
}
button.btn.btn-view.ml-2.mr-2{
    background-color: #6d6d6d;
    
}
button.btn.btn-view.ml-2.mr-2:hover{
    background-color:#e74c3c;
}
button.btn.btn-save{
    background-color:#e74c3c;
}


@media only screen and (max-width: 600px) {
    /* For mobile phones: */
    .btn-view{
      width: 70px;
    }

    .btn-save{
        background: #e74c3c;
        color: #ffffff;
        font-weight: bold;
        width:60px;
    }
    .col.heading{
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .col.content{
        padding-top: 2px;
        padding-bottom: 2px;
    }
    
  }

  @media only screen and (max-width: 760px) {
    .btn-view{
      width: 70px;
    }

    .btn-save{
        background: #e74c3c;
        color: #ffffff;
        font-weight: bold;
        width:60px;
    }

    
  }

  @media only screen and (max-width: 1140px) {
    .btn-view{
      width: 70px;
    }

    .btn-save{
        background: #e74c3c;
        color: #ffffff;
        font-weight: bold;
        width:60px;
    }
    
  }
