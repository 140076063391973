

.test{
    width:100%
}
.customViewer {
    border-color: #fa5b35;
    border-width: 1px;
    border-style: solid;
}
.customCanvas {
   
    min-width: 100%;
    max-width: 100%;
    overflow: auto;
    height:100vh;

}
.customPrevBtn {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: auto;
    color: #fa5b35;
    background-color: #f3f3f3;
    border-color: #fa5b35;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
}
.customNextBtn {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: auto;
    color: #fa5b35;
    background-color: #f3f3f3;
    border-color: #333333;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
}
.customResetBtn {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: auto;
    color: #45fa35;
    background-color: #f3f3f3;
    border-color: #45fa35;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
}
.customPages {
    font-size: medium;
    margin: 0.5rem;
}
.customWrapper {
    height: 40px;
    font-size: 24px;
    padding: 12px;
    padding-bottom: 0;
    background-color: #fff;
}


.download_button{
    display: block;
    width: 100%;
    background: #1890ff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 25px;
}


.focused{
    background:red !important;
    font-weight: bold;
    color:white;
}