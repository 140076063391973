/********Graph section************/



/*dashboard style  record */
.record-card .card-text{
    text-align: center;
    font-family: Lato;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #6d6d6d;
  }
  
  .card{
    text-align: center;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  
  .card h1{
    font-family: Lato;
    font-size: 62px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #e95748;
  }
  
  .card span{
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #e95748;
  }
  
  .card h3{
    font-family: Lato;
    font-size: 35px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  
  .card p{
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  
  /* ***************** */
  .graph-section .card-title{
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #6d6d6d;
  }
  
  .square1{
    color: #e74c3c;
  }
  
  .square2{
    color: #000000;
  }
  
  .square3{
    color:  #c1c1c1;
  }



  .recharts-wrapper, .recharts-surface, .recharts-legend-wrapper {
    width: 100% !important;
  }

  .graph-section .row, .record-area .row {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  @media (max-width: 991px) {
    .graph-section .card-body {
      padding: 1.25rem 0.5rem;
    }
  }