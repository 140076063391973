*{
    font-family: 'Lato', sans-serif;
}

.container-fluid {
    background-color: #ffffff;
}

.heading{
    font-family: Lato;
    font-size: 40px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #e74c3c;
    padding: 30px;
}
center{
    font-size: 40px;
    color: #e74c3c;
}

.form-control{
    font-family: 'Lato', sans-serif;
    padding: 0px 0px;
    font-size: 14px;
    border: none;
    background-color:#fdf8f8;
    color: #e74c3c;
    padding: 0px 10px;
}

.form-control:focus {
    border-color: #ff80ff;
    box-shadow: none;
}

.email{
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #e74c3c;
    padding: 0px 10px;
    margin: 0px;
  }

.form-group{
    background-color:#fdf8f8;
}

.form-group i{
    float: left;
}

.btn{
    background-color: #e74c3c;
    color: #ffffff;
    font-size: 16px;
    height: 50px;
    font-weight: bold;
}
button.btn.btn-block{
    background-color: #e74c3c;
}
button.btn.btn-block{
    color: white;
}

.sign-up{
    text-align: center;
    font-size: 20px;
    padding: 10px 0px;
    color: #6d6d6d;
}

.sign-up a{
    color: #e74c3c;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
}