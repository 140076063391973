.tags{ background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);
}

.breadcrumbs { 
    list-style: none; 
    overflow: hidden; 
    padding: 0;
  }
  .breadcrumbs li { 
    float: left; 
  }
  .breadcrumbs li a {
    color: white;
    text-decoration: none; 
    padding: 10px 0 10px 55px;
    background: brown; /* fallback color */
    background: hsla(34,85%,35%,1); 
    position: relative; 
    display: block;
    float: left;
  }
  .breadcrumbs li a:after { 
    content: " "; 
    display: block; 
    width: 0; 
    height: 0;
    border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid hsla(34,85%,35%,1);
    position: absolute;
    top: 50%;
    margin-top: -50px; 
    left: 100%;
    z-index: 2; 
  }   
  .breadcrumbs li a:before { 
    content: " "; 
    display: block; 
    width: 0; 
    height: 0;
    border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px; 
    margin-left: 1px;
    left: 100%;
    z-index: 1; 
  }   
  .breadcrumbs li:first-child a {
    padding-left: 10px;
  }
  .breadcrumbs li a       { background:        hsla(34,85%,45%,1); }
  .breadcrumbs li a:after { border-left-color: hsla(34,85%,45%,1); }
 
  .breadcrumbs li a:hover { background: hsla(34,85%,25%,1); }
  .breadcrumbs li a:hover:after { border-left-color: hsla(34,85%,25%,1) !important; }
  
  
  .steps {
    margin: 40px;
    padding: 0;
    overflow: hidden;
  }
  .steps a {
    color: white;
    text-decoration: none;
  }
  .steps em {
    display: block;
    font-size: 1.1em;
    font-weight: bold;
  }
  .steps li {
    float: left;
    margin-left: 0;
    width: 150px; /* 100 / number of steps */
    height: 70px; /* total height */
    list-style-type: none;
    padding: 5px 5px 5px 30px; /* padding around text, last should include arrow width */
    border-right: 3px solid white; /* width: gap between arrows, color: background of document */
    position: relative;
  }
  /* remove extra padding on the first object since it doesn't have an arrow to the left */
  .steps li:first-child {
    padding-left: 5px;
  }
  /* white arrow to the left to "erase" background (starting from the 2nd object) */
  .steps li:nth-child(n+2)::before {
    position: absolute;
    top:0;
    left:0;
    display: block;
    border-left: 25px solid white; /* width: arrow width, color: background of document */
    border-top: 40px solid transparent; /* width: half height */
    border-bottom: 40px solid transparent; /* width: half height */
    width: 0;
    height: 0;
    content: " ";
  }
  /* colored arrow to the right */
  .steps li::after {
    z-index: 1; /* need to bring this above the next item */
    position: absolute;
    top: 0;
    right: -25px; /* arrow width (negated) */
    display: block;
    border-left: 25px solid #7c8437; /* width: arrow width */
    border-top: 40px solid transparent; /* width: half height */
    border-bottom: 40px solid transparent; /* width: half height */
    width:0;
    height:0;
    content: " ";
  }
  
  /* Setup colors (both the background and the arrow) */
  
  /* Completed */
  .steps li { background-color: #7C8437; }
  .steps li::after { border-left-color: #7c8437; }
  
  /* Current */
  .steps li.current { background-color: #C36615; }
  .steps li.current::after { border-left-color: #C36615; }
  
  /* Following */
  .steps li.current ~ li { background-color: #EBEBEB; }
  .steps li.current ~ li::after { border-left-color: #EBEBEB; }
  
  /* Hover for completed and current */
  .steps li:hover {background-color: #696}
  .steps li:hover::after {border-left-color: #696}
  
  
  
  .arrows { white-space: nowrap; }
  .arrows li {
      display: inline-block;
      line-height: 26px;
      margin: 0 9px 0 -10px;
      padding: 0 20px;
      position: relative;
  }
  .arrows li::before,
  .arrows li::after {
      border-right: 1px solid #666666;
      content: '';
      display: block;
      height: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: -1;
      transform: skewX(45deg);   
  }
  .arrows li::after {
      bottom: 0;
      top: auto;
      transform: skewX(-45deg);
  }
  
  .arrows li:last-of-type::before, 
  .arrows li:last-of-type::after { 
      display: none; 
  }
  
  .arrows li a { 
     font: bold 24px Sans-Serif;  
     letter-spacing: -1px; 
     text-decoration: none;
  }
  
  .arrows li:nth-of-type(1) a { color: hsl(0, 0%, 70%); } 
  .arrows li:nth-of-type(2) a { color: hsl(0, 0%, 65%); } 
  .arrows li:nth-of-type(3) a { color: hsl(0, 0%, 50%); } 
  .arrows li:nth-of-type(4) a { color: hsl(0, 0%, 45%); } 