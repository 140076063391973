.form-div{
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  
  .cancel{
      color: #e74c3c;
      text-align: right;
      margin-right: 25px;
      padding: 10px 0px;
  }
  
  .form-group label{
      font-size: 14px;
  }
  
  .form-control{
      font-size: 14px;
  }
  
  .btn{
      background-color: #e74c3c;
      width: 200px;
      color: #ffffff;
      font-weight: bold;
      padding: 5px;
  }
  
  .btn:hover{
      color:#ffffff;
  }
  .search-btn:hover{
    color:red;
  }
  button.btn.search-btn.w-100.p-0.m-0.btn-sm{
    color: #e74c3c;
}
/* th:row {
  border-left-style: none;
  border-top-left-radius: none; 
  border-bottom-left-radius: none;
} */
th{
  border-left-style: none;
} 
th:first-child {
  /* border-left-style: solid; */
  border-top-left-radius: 100px; 
  border-bottom-left-radius: 100px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px; 
  border-top-right-radius: 10px; 
}

  /* is active button */
  
  .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 32px;
    }
    
    .switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .switch input:checked + .slider {
      background-color: #42BF04;
    }
    
    .switch input:focus + .slider {
      box-shadow: 0 0 1px #42BF04;
    }
    
    .switch input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }

/*     
.pop-btn{
  background-color: #ffffff;
  width: 100px;
  color: #e74c3c;
  border-radius: 50px;
  width: 150px;
  height: 35px;
} */

/* .pop-btn:hover{
  color: #e74c3c;
} */




/* ******** sub menu and Search Box Here ********* */

.search-box{
  background-color: #e74c3c;
  color: #ffffff;
  font-weight: bold;
}
.search-btn{
  border-radius: 30px;
  background-color: #ffffff;
  color: #e74c3c;
  font-weight: bold;
  height: 35px;
}

.fa-search{
  color: #ffffff;
}


.select-field{
  border-radius: 30px;
  background-color: #ffffff;
  color: #e74c3c;
  font-weight: bold;
}

.select-field option{
  color: #e74c3c;
}

.input-group.md-form.form-sm.form-2 input.lime-border {
color: #e74c3c;
border-radius: 35px 0px 0px 35px;
}
table.row-space {
  width: -webkit-fill-available;

}

.solid{
  border-radius: 25px;
  background-color: white;
}


.loading{
position : absolute;
top : 50%;
left : 50%;
transform : translate(-50%, -50%);
z-index: 9999;
}