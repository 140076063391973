.btn-toggle {
    top: 50%;
    transform: translateY(-50%);
}

.btn-toggle {
    margin: 0 4rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1.5rem;
    color: #6b7381;
}

.align-center{
    text-align: center;
}

.margin-top{
    margin-top: 0px !important;
}

.col-sm-5 .btn-toggle {
    background: #bdc1c8;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
    outline: none;
}

.btn-toggle:focus {
    box-shadow: none;
}

.btn-toggle:before,
.btn-toggle:after {
    line-height: 1.5rem;
    width: 4rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}

.btn-toggle:before {
    content: 'Off';
    left: -4rem;
}

.btn-toggle:after {
    content: 'On';
    right: -4rem;
    opacity: 0.5;
}

.btn-toggle > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
}

.btn-toggle.active {
    transition: background-color 0.25s;
}

.btn-toggle.active > .handle {
    left: 1.6875rem;
    transition: left 0.25s;
}

.btn-toggle.active:before {
    opacity: 0.5;
}
.btn-toggle.active:after {
    opacity: 1;
}

.btn-toggle:before,
.btn-toggle:after {
    color: #6b7381;
}

.btn-toggle.active {
    background-color: #e74c3c;
}

.forced-learning-adjust input[type="number"] {
    width: 100px;
    margin: 0 5px;
    padding: 5px;
    background: none;
    font-size: 12px;
    color: #e74c3c;
    border: 1px solid #bdc1c8;
    border-radius: 5px;
    transition: all 0.25s;
}

.forced-learning-adjust input[type="number"]:focus {
    outline: none;
    border-color: #e74c3c;
}

.forced-learning-adjust {
    margin-top: 50px;
}

.forced-learning-adjust .col-sm-5:nth-of-type(2) {
    text-align: center;
    margin: 30px 0;
}

.settings-save {
    text-align: center;
}
.settings-save button {
    margin: 10px;
    padding: 3px 21px;
    background: none;
    border: 1px solid #e74c3c;
    color: #e74c3c;
    border-radius: 5px;
}
.settings-save button:focus {
    outline: none;
}

.settings-save button:hover {
    background: #e74c3c;
    color: #fff;
    transition: all 0.25s;
}